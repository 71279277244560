import HeaderContent from "./components/HeaderContent";
import { usePathname, useParams } from "next/navigation";
import { useMemo } from "react";
import WhiteLogo from "@/assets/logos/white-logo.png";
import PurpleLogo from "@/assets/logos/color-logo.png";
import useLocale from "@/hooks/useLocale";
import localizePath from "@/functions/localizePath";

export default function Header() {
  const pathname = usePathname();
  const params = useParams();
  const locale = useLocale();

  const navStyle = useMemo(() => {
    const url_slug = decodeURI(params?.url_slug);
    const path = decodeURI(pathname);

    if (
      [
        localizePath(`/newsroom/${url_slug}`, locale),
        localizePath("/jobs/thank-you-for-applying", locale),
        localizePath(`/case-studies/${url_slug}`, locale),
        localizePath("/not-found", locale),
      ].includes(path)
    ) {
      return {
        color: "text-purple",
        img: PurpleLogo,
        btnBg: "green",
        btnColor: "purple",
        menu: "bg-purple",
        icon: "fill-purple",
        searchIcon: "fill-purple",
      };
    }

    if (
      [
        localizePath(`/jobs/${url_slug}`, locale),
        localizePath("/vacancies", locale),
      ].includes(path)
    ) {
      return {
        color: "text-purple",
        img: PurpleLogo,
        btnBg: "purple",
        btnColor: "white",
        menu: "bg-purple",
        icon: "fill-purple",
        searchIcon: "fill-white",
      };
    }

    return {
      color: "text-white",
      img: WhiteLogo,
      btnBg: "green",
      btnColor: "white",
      menu: "bg-white",
      icon: "fill-white",
      searchIcon: "fill-purple",
    };
  }, [params?.url_slug, pathname, locale]);

  return (
    <>
      <div className="fixed left-0 right-0 z-[1000]">
        <HeaderContent navStyle={navStyle} />
      </div>
    </>
  );
}
